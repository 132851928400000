import Vue from 'vue'

import brokerPortalDomains from '@/src/broker-portal-domains'

const adminPages = [
  'announcements',
  'domains',
  'faqs',
  'icp-adjustments', 'icp-upload-download',
  'ratesbot',
  'offer-offer-num'
]

const brokerPages = [
  'icp',
  'esign',
  'payment-token-add', 'payment-token-get',
  'users',
  'search-policy'
]

const servicePages = [
  'library', 'library-links',
  'change-requests-id',
  'offer-offer-num'
]

const clientPages = [
  'index',
  'cross-sell-quoter-error', 'cross-sell-quoter-quote_id', 'cross-sell-quoter-quote_id-quote',
  'profile', 'policy-num', 'policy-num-vehicle-vehicleNum', 'policy-num-location-locationNum',
  'change-requests', 'change-requests-select-policy-type', 'change-requests-auto-num', 'change-requests-auto-num-select-change-type', 'change-requests-auto-num-changeType-step', 'change-requests-auto-select-policy',
  'payments',
  'claims',
  'documents',
  'policy',
  'contact-us',
  'help',
  'offer-offer-num'
]

// this is used to encapsulate
class AuthService {
  constructor (store) {
    this.$store = store
  }

  get isAuthenticated () {
    return this.$store.state.auth.isAuthenticated
  }

  get user () {
    return this.$store.state.auth.user
  }

  get email () {
    if (!this.user) { return }
    return this.user.attributes.email
  }

  get emailVerified () {
    if (!this.user) { return }
    return this.user.attributes.email_verified
  }

  get phoneNumber () {
    if (!this.user) { return }
    return this.user.attributes.phone_number
  }

  get phoneNumberVerified () {
    if (!this.user) { return }
    return this.user.attributes.phone_number_verified
  }

  get mfa () {
    if (!this.user) { return }
    return this.user.preferredMFA === 'SMS_MFA'
  }

  get fullName () {
    if (!this.user ||
        !this.user.attributes.family_name ||
        !this.user.attributes.given_name) { return }
    return `${this.user.attributes.given_name} ${this.user.attributes.family_name}`
  }

  get lookupCodes () {
    if (!this.user ||
        !this.user.attributes['custom:lookup_codes']) { return }
    return this.user.attributes['custom:lookup_codes']
  }

  get domains () {
    if (!this.user ||
        !this.user.attributes['custom:domains']) { return }
    return this.user.attributes['custom:domains']
  }

  get groups () {
    if (!this.user ||
        !this.user.signInUserSession ||
        !this.user.signInUserSession.idToken ||
        !this.user.signInUserSession.idToken.payload) { return }
    return this.user.signInUserSession.idToken.payload['cognito:groups']
  }

  get providerType () {
    if (!this.user ||
      !this.user.signInUserSession ||
      !this.user.signInUserSession.idToken ||
      !this.user.signInUserSession.idToken.payload ||
      !this.user.signInUserSession.idToken.payload.identities ||
      !this.user.signInUserSession.idToken.payload.identities[0]) { return }
    return this.user.signInUserSession.idToken.payload.identities[0].providerType
  }

  get isAdmin () {
    return this.groups && !!this.groups.find(g => g === 'Admin')
  }

  get isTeamLead () {
    return this.groups && !!this.groups.find(g => g.match(/^(Service|Sales)TeamLead$/))
  }

  get isBroker () {
    return this.groups && !!this.groups.find(g => g.match(/^(Service|Sales)Broker$/))
  }

  get isSales () {
    return this.groups && !!this.groups.find(g => g.match(/^Sales(?:Broker|TeamLead)$/))
  }

  get isService () {
    return this.groups && !!this.groups.find(g => g.match(/^Service(?:Broker|TeamLead)$/))
  }

  get isCustomer () {
    return this.groups && this.groups.length === 1 && this.groups[0] === 'User'
  }

  get isWorker () {
    return this.isAdmin || this.isService || this.isSales
  }

  get isViewAs () {
    return (this.lookupCodes && this.groups && this.groups.find(e => e.match(/^(Admin|SalesBroker|SalesTeamLead|ServiceBroker|ServiceTeamLead)$/)))
  }

  get isBrokerPortal () {
    return location.host.includes('localhost') || brokerPortalDomains.includes(location.host)
  }

  get primaryGroup () {
    let group = ''
    if (this.isAdmin === true) {
      group = 'Admin'
    } else if (this.isService === true) {
      group = 'Service'
    } else if (this.isSales === true) {
      group = 'Sales'
    } else if (this.isCustomer === true) {
      group = 'User'
    }
    return group
  }

  get availableCompanies () {
    const availableCompanies = {
      Admin: ['CAAIN1'],
      Sales: ['CAAIN1'],
      Service: ['CAAIN1'],
      User: ['CAAIN1']
    }
    return availableCompanies[this.primaryGroup]
  }

  get pagePermissions () {
    return {
      Admin: this.isViewAs ? [...clientPages] : [...adminPages, ...brokerPages, ...servicePages],
      Service: this.isViewAs ? [...clientPages] : [...brokerPages, ...servicePages],
      Sales: this.isViewAs ? [...clientPages] : [...brokerPages],
      User: clientPages.filter(x => !x.includes('cross-sell-quoter')) // TEMP: disabling cross-sell for customers
    }
  }
}

export default async (context, inject) => {
  const authService = new AuthService(context.store)
  context.$auth = Vue.prototype.$auth = Vue.$auth = authService
  inject('auth', authService)
  await context.store.dispatch('auth/load', { bypassCache: true })
}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $email: String!
    $groups: [String]!
    $domains: [String]!
    $custom__lookup_codes: String
    $custom__domains: String
    $phone: String
    $suppressMsg: Boolean
  ) {
    createUser(
      email: $email
      groups: $groups
      domains: $domains
      custom__lookup_codes: $custom__lookup_codes
      custom__domains: $custom__domains
      phone: $phone
      suppressMsg: $suppressMsg
    ) {
      username
      email
      status
      enabled
      groups
      userCreateDate
      userLastModifiedDate
      custom__lookup_codes
      custom__domains
      phone
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $username: String!
    $email: String!
    $groups: [String]
    $domains: [String]!
    $custom__lookup_codes: String
    $custom__domains: String
    $phone: String
  ) {
    updateUser(
      username: $username
      email: $email
      groups: $groups
      domains: $domains
      custom__lookup_codes: $custom__lookup_codes
      custom__domains: $custom__domains
      phone: $phone
    ) {
      username
      email
      status
      enabled
      groups
      userCreateDate
      userLastModifiedDate
      custom__lookup_codes
      custom__domains
      phone
    }
  }
`;
export const requestInvitationLink = /* GraphQL */ `
  mutation RequestInvitationLink(
    $recaptchaToken: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
  ) {
    requestInvitationLink(
      recaptchaToken: $recaptchaToken
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
    )
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $uniqPolicy: Int!
    $lookupCode: String!
    $activityCode: String!
    $note: [String]
    $openStatus: String
    $closedStatus: String
    $closedReason: String
  ) {
    createActivity(
      uniqPolicy: $uniqPolicy
      lookupCode: $lookupCode
      activityCode: $activityCode
      note: $note
      openStatus: $openStatus
      closedStatus: $closedStatus
      closedReason: $closedReason
    )
  }
`;
export const createActivityEntity = /* GraphQL */ `
  mutation CreateActivityEntity(
    $lookupCode: String!
    $activityCode: String!
    $note: [String]
    $openStatus: String
    $closedStatus: String
    $closedReason: String
    $whoOwnerEmail: String
  ) {
    createActivityEntity(
      lookupCode: $lookupCode
      activityCode: $activityCode
      note: $note
      openStatus: $openStatus
      closedStatus: $closedStatus
      closedReason: $closedReason
      whoOwnerEmail: $whoOwnerEmail
    )
  }
`;
export const createActivityEntityUser = /* GraphQL */ `
  mutation CreateActivityEntityUser(
    $lookupCode: String!
    $activityCode: String!
    $note: [String]
    $openStatus: String
    $closedStatus: String
    $closedReason: String
    $whoOwnerEmail: String
  ) {
    createActivityEntityUser(
      lookupCode: $lookupCode
      activityCode: $activityCode
      note: $note
      openStatus: $openStatus
      closedStatus: $closedStatus
      closedReason: $closedReason
      whoOwnerEmail: $whoOwnerEmail
    )
  }
`;
export const updateActivityEntity = /* GraphQL */ `
  mutation UpdateActivityEntity(
    $lookupCode: String!
    $activityId: Int!
    $payload: String!
  ) {
    updateActivityEntity(
      lookupCode: $lookupCode
      activityId: $activityId
      payload: $payload
    )
  }
`;
export const deletePciVaultUrl = /* GraphQL */ `
  mutation DeletePciVaultUrl($url: String!) {
    deletePciVaultUrl(url: $url)
  }
`;
export const postEvent = /* GraphQL */ `
  mutation PostEvent($scopeId: String!, $eventType: String!) {
    postEvent(scopeId: $scopeId, eventType: $eventType) {
      scopeId
      eventType
    }
  }
`;
export const createTrackingFormstack = /* GraphQL */ `
  mutation CreateTrackingFormstack(
    $activityId: Int!
    $documentId: String!
    $lookupCode: String!
  ) {
    createTrackingFormstack(
      activityId: $activityId
      documentId: $documentId
      lookupCode: $lookupCode
    )
  }
`;
export const updateTrackingFormstack = /* GraphQL */ `
  mutation UpdateTrackingFormstack(
    $id: String!
    $completed: Boolean
    $participantsSigned: [InsureSignParticipant]
  ) {
    updateTrackingFormstack(
      id: $id
      completed: $completed
      participantsSigned: $participantsSigned
    )
  }
`;
export const updateTrackingIcp = /* GraphQL */ `
  mutation UpdateTrackingIcp($id: String!, $isDeleted: Boolean!) {
    updateTrackingIcp(id: $id, isDeleted: $isDeleted)
  }
`;
export const uploadSignedDocument = /* GraphQL */ `
  mutation UploadSignedDocument(
    $activityId: Int!
    $documentId: String!
    $lookupCode: String!
  ) {
    uploadSignedDocument(
      activityId: $activityId
      documentId: $documentId
      lookupCode: $lookupCode
    )
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($username: String!) {
    deleteUser(username: $username)
  }
`;
export const disableUser = /* GraphQL */ `
  mutation DisableUser($username: String!) {
    disableUser(username: $username)
  }
`;
export const enableUser = /* GraphQL */ `
  mutation EnableUser($username: String!) {
    enableUser(username: $username)
  }
`;
export const signOutUser = /* GraphQL */ `
  mutation SignOutUser($username: String!) {
    signOutUser(username: $username)
  }
`;
export const resetUserPassword = /* GraphQL */ `
  mutation ResetUserPassword($username: String!) {
    resetUserPassword(username: $username)
  }
`;
export const resendTempPassword = /* GraphQL */ `
  mutation ResendTempPassword($username: String!) {
    resendTempPassword(username: $username) {
      username
      email
      status
      enabled
      groups
      userCreateDate
      userLastModifiedDate
      custom__lookup_codes
      custom__domains
      phone
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $name: String!
    $description: String
    $precedence: Int!
  ) {
    createGroup(
      name: $name
      description: $description
      precedence: $precedence
    ) {
      name
      description
      createDate
      lastModifiedDate
      precedence
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $name: String!
    $description: String
    $precedence: Int!
  ) {
    updateGroup(
      name: $name
      description: $description
      precedence: $precedence
    ) {
      name
      description
      createDate
      lastModifiedDate
      precedence
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup($name: String!) {
    deleteGroup(name: $name)
  }
`;
export const forgetDevice = /* GraphQL */ `
  mutation ForgetDevice($deviceKey: String!) {
    forgetDevice(deviceKey: $deviceKey)
  }
`;
export const createDomain = /* GraphQL */ `
  mutation CreateDomain($name: String!, $description: String) {
    createDomain(name: $name, description: $description) {
      id
      name
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDomain = /* GraphQL */ `
  mutation UpdateDomain($id: String!, $name: String!, $description: String) {
    updateDomain(id: $id, name: $name, description: $description) {
      id
      name
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDomain = /* GraphQL */ `
  mutation DeleteDomain($id: String!) {
    deleteDomain(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $message: String!
    $order: Int
    $enabled: Boolean
  ) {
    createAnnouncement(message: $message, order: $order, enabled: $enabled) {
      id
      message
      order
      enabled
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $id: String!
    $message: String!
    $order: Int
    $enabled: Boolean
  ) {
    updateAnnouncement(
      id: $id
      message: $message
      order: $order
      enabled: $enabled
    ) {
      id
      message
      order
      enabled
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement($id: String!) {
    deleteAnnouncement(id: $id) {
      id
      message
      order
      enabled
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFaq = /* GraphQL */ `
  mutation CreateFaq(
    $question: String!
    $answer: String!
    $category: String!
    $order: Int
  ) {
    createFaq(
      question: $question
      answer: $answer
      category: $category
      order: $order
    ) {
      id
      question
      answer
      category
      order
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFaq = /* GraphQL */ `
  mutation UpdateFaq(
    $id: String!
    $question: String!
    $answer: String!
    $category: String!
    $order: Int
  ) {
    updateFaq(
      id: $id
      question: $question
      answer: $answer
      category: $category
      order: $order
    ) {
      id
      question
      answer
      category
      order
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFaq = /* GraphQL */ `
  mutation DeleteFaq($id: String!) {
    deleteFaq(id: $id) {
      id
      question
      answer
      category
      order
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createLibraryLink = /* GraphQL */ `
  mutation CreateLibraryLink($linkId: String!, $libraryKey: String!) {
    createLibraryLink(linkId: $linkId, libraryKey: $libraryKey) {
      id
      linkId
      libraryKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLibraryLink = /* GraphQL */ `
  mutation UpdateLibraryLink(
    $id: String!
    $linkId: String!
    $libraryKey: String!
  ) {
    updateLibraryLink(id: $id, linkId: $linkId, libraryKey: $libraryKey) {
      id
      linkId
      libraryKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLibraryLink = /* GraphQL */ `
  mutation DeleteLibraryLink($id: String!) {
    deleteLibraryLink(id: $id) {
      id
      linkId
      libraryKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const sendWalletPass = /* GraphQL */ `
  mutation SendWalletPass(
    $lookupCode: String!
    $policyId: String!
    $policyCode: String!
  ) {
    sendWalletPass(
      lookupCode: $lookupCode
      policyId: $policyId
      policyCode: $policyCode
    )
  }
`;
export const putFile = /* GraphQL */ `
  mutation PutFile(
    $policyId: String!
    $content: String!
    $name: String!
    $description: String!
    $attachedToType: String
  ) {
    putFile(
      policyId: $policyId
      content: $content
      name: $name
      description: $description
      attachedToType: $attachedToType
    ) {
      attachmentId
      extension
      insertedDate
      description
      content
      policyId
      policyNumber
    }
  }
`;
export const registerClientToAgency = /* GraphQL */ `
  mutation RegisterClientToAgency($status: String!, $username: String) {
    registerClientToAgency(status: $status, username: $username)
  }
`;
export const createScheduledCall = /* GraphQL */ `
  mutation CreateScheduledCall(
    $clientName: String!
    $clientEmail: String!
    $clientCallbackStart: String!
    $clientCallbackEnd: String!
    $destinationPhoneNumber: String
  ) {
    createScheduledCall(
      clientName: $clientName
      clientEmail: $clientEmail
      clientCallbackStart: $clientCallbackStart
      clientCallbackEnd: $clientCallbackEnd
      destinationPhoneNumber: $destinationPhoneNumber
    )
  }
`;
export const updateScheduledCall = /* GraphQL */ `
  mutation UpdateScheduledCall(
    $id: String!
    $clientName: String!
    $clientEmail: String!
    $clientCallbackStart: String!
    $clientCallbackEnd: String!
    $destinationPhoneNumber: String
    $isDeletedByUser: Boolean
    $uniqActivity: Int!
  ) {
    updateScheduledCall(
      id: $id
      clientName: $clientName
      clientEmail: $clientEmail
      clientCallbackStart: $clientCallbackStart
      clientCallbackEnd: $clientCallbackEnd
      destinationPhoneNumber: $destinationPhoneNumber
      isDeletedByUser: $isDeletedByUser
      uniqActivity: $uniqActivity
    )
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact($phone: String, $email: String) {
    updateContact(phone: $phone, email: $email)
  }
`;
export const adminUpdateContact = /* GraphQL */ `
  mutation AdminUpdateContact(
    $lookupCode: String!
    $phone: String
    $email: String
  ) {
    adminUpdateContact(lookupCode: $lookupCode, phone: $phone, email: $email)
  }
`;
export const sendFeedbackToEpic = /* GraphQL */ `
  mutation SendFeedbackToEpic($score: String!, $feedback: String) {
    sendFeedbackToEpic(score: $score, feedback: $feedback)
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $uniqPolicy: Int!
    $lookupCode: String!
    $activityId: Int!
    $payload: String!
  ) {
    updateActivity(
      uniqPolicy: $uniqPolicy
      lookupCode: $lookupCode
      activityId: $activityId
      payload: $payload
    )
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($emailType: String!, $emailData: String!) {
    sendEmail(emailType: $emailType, emailData: $emailData)
  }
`;
export const sendActOnEmail = /* GraphQL */ `
  mutation SendActOnEmail($formExt: String!, $emailData: String!) {
    sendActOnEmail(formExt: $formExt, emailData: $emailData)
  }
`;
export const updateBankingPayment = /* GraphQL */ `
  mutation UpdateBankingPayment($payload: BankingPaymentInput!) {
    updateBankingPayment(payload: $payload)
  }
`;
export const updateCreditCardPayment = /* GraphQL */ `
  mutation UpdateCreditCardPayment($payload: CreditPaymentInput!) {
    updateCreditCardPayment(payload: $payload)
  }
`;
export const makeCreditCardPayment = /* GraphQL */ `
  mutation MakeCreditCardPayment($payload: CreditPaymentInput!) {
    makeCreditCardPayment(payload: $payload)
  }
`;
export const createChangeRequest = /* GraphQL */ `
  mutation CreateChangeRequest(
    $uniqPolicy: Int!
    $payload: String!
    $isDeleted: Boolean
  ) {
    createChangeRequest(
      uniqPolicy: $uniqPolicy
      payload: $payload
      isDeleted: $isDeleted
    ) {
      id
      username
      uniqPolicy
      payload
      isDeleted
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateChangeRequest = /* GraphQL */ `
  mutation UpdateChangeRequest(
    $id: String!
    $uniqPolicy: Int!
    $payload: String!
    $isDeleted: Boolean
  ) {
    updateChangeRequest(
      id: $id
      uniqPolicy: $uniqPolicy
      payload: $payload
      isDeleted: $isDeleted
    ) {
      id
      username
      uniqPolicy
      payload
      isDeleted
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteChangeRequest = /* GraphQL */ `
  mutation DeleteChangeRequest($id: String!) {
    deleteChangeRequest(id: $id) {
      id
      username
      uniqPolicy
      payload
      isDeleted
      createdAt
      updatedAt
      owner
    }
  }
`;
export const changeRequestActivity = /* GraphQL */ `
  mutation ChangeRequestActivity(
    $uniqPolicy: Int!
    $lookupCode: String!
    $action: String!
    $activityCode: ActivityCode
  ) {
    changeRequestActivity(
      uniqPolicy: $uniqPolicy
      lookupCode: $lookupCode
      action: $action
      activityCode: $activityCode
    )
  }
`;
export const submitChangeRequest = /* GraphQL */ `
  mutation SubmitChangeRequest($uniqPolicy: Int!, $lookupCode: String!) {
    submitChangeRequest(uniqPolicy: $uniqPolicy, lookupCode: $lookupCode)
  }
`;
export const createRatesbotSettings = /* GraphQL */ `
  mutation CreateRatesbotSettings($payload: String!) {
    createRatesbotSettings(payload: $payload)
  }
`;
export const runRatesbotRequestFunction = /* GraphQL */ `
  mutation RunRatesbotRequestFunction(
    $lookupCode: String!
    $uniqPolicy: Int!
    $data: String!
    $origin: String!
    $email: String
  ) {
    runRatesbotRequestFunction(
      lookupCode: $lookupCode
      uniqPolicy: $uniqPolicy
      data: $data
      origin: $origin
      email: $email
    ) {
      statusCode
      uniqPolicy
      uniqEntity
      companyCode
      policyNumber
      lookupCode
      payload
      isOffer
      scenario
      state
      errorMessage
      updatedDate
    }
  }
`;
export const insertQuote = /* GraphQL */ `
  mutation InsertQuote($origin: String!, $payload: String!) {
    insertQuote(origin: $origin, payload: $payload) {
      id
      origin
      lookupCode
      payload
      statusCode
      state
      errorMessage
    }
  }
`;
export const runPropertyMapperFunction = /* GraphQL */ `
  mutation RunPropertyMapperFunction($id: String!, $origin: String!) {
    runPropertyMapperFunction(id: $id, origin: $origin) {
      id
      origin
      lookupCode
      payload
      statusCode
      state
      errorMessage
    }
  }
`;
export const runPropertyRequestFunction = /* GraphQL */ `
  mutation RunPropertyRequestFunction($id: String!, $origin: String!) {
    runPropertyRequestFunction(id: $id, origin: $origin)
  }
`;
export const createTracking = /* GraphQL */ `
  mutation CreateTracking(
    $email: String
    $page: String
    $action: String
    $lineOfBusiness: String
    $status: String
    $errorCode: String
    $payload: String
  ) {
    createTracking(
      email: $email
      page: $page
      action: $action
      lineOfBusiness: $lineOfBusiness
      status: $status
      errorCode: $errorCode
      payload: $payload
    )
  }
`;
export const uploadTeamDataFile = /* GraphQL */ `
  mutation UploadTeamDataFile($body: String!, $key: String!) {
    uploadTeamDataFile(body: $body, key: $key)
  }
`;
export const generateDocument = /* GraphQL */ `
  mutation GenerateDocument($documents: [FormstackDocument]!) {
    generateDocument(documents: $documents)
  }
`;
export const sendDocument = /* GraphQL */ `
  mutation SendDocument(
    $fields: [InsureSignField]!
    $lookupCode: String!
    $participants: [InsureSignParticipant]!
    $s3Key: String!
  ) {
    sendDocument(
      fields: $fields
      lookupCode: $lookupCode
      participants: $participants
      s3Key: $s3Key
    ) {
      sendStatus
      signingLink
    }
  }
`;

import Vue from 'vue'

const getDefaultState = () => {
  return {
    auto: {},
    home: {},
    commercialAuto: {},
    commercialProperty: {},
    changeRequests: {},
    activities: {},
    specialOffers: {},
    preOffers: {},
    specialOffersByBroker: {},
    preOffersByBroker: {},
    crossSell: {}
  }
}

export const state = () => getDefaultState()

const controller = new AbortController()

export const mutations = {
  SET_POLICY (s, { policyType, policyId, policy }) {
    s[policyType][policyId] = { ...policy }
  },
  SET_POLICY_APPLICANT (s, { policyType, policyId, applicant }) {
    s[policyType][policyId].applicant = { ...applicant }
  },
  SET_POLICY_ERRORS (s, { policyType, policyId, errors }) {
    s[policyType][policyId].errors = errors
  },
  SET_POLICY_PAYMENT_DETAILS (s, { policyType, policyId, paymentDetails }) {
    s[policyType][policyId].paymentDetails = paymentDetails
  },
  SET_POLICIES (s, { policyType, policies }) {
    s[policyType] = { ...policies }
  },
  CLEAR_POLICIES (s) {
    Object.assign(s, getDefaultState())
  },
  SET_CHANGE_REQUEST (s, { uniqPolicy, payload }) {
    s.changeRequests[uniqPolicy] = { ...payload }
  },
  SET_CHANGE_REQUESTS_KEY (s, { key, value }) {
    s.changeRequests[key] = value
  },
  REMOVE_CHANGE_REQUEST (s, uniqPolicy) {
    delete s.changeRequests[uniqPolicy]
  },
  REMOVE_CHANGE_REQUESTS_KEY (s, key) {
    delete s.changeRequests[key]
  },
  SET_POLICY_ACTIVITIES (s, { uniqPolicy, activities }) {
    s.activities[uniqPolicy] = [...activities]
  },
  SET_SPECIAL_OFFER (s, { uniqPolicy, policyNumber, acitvityCode, scenario, insertedDate, seen }) {
    s.specialOffers[uniqPolicy] = {
      uniqPolicy,
      policyNumber,
      acitvityCode,
      scenario,
      insertedDate,
      seen
    }
  },
  SET_SPECIAL_OFFER_SEEN (s, { uniqPolicy, seen }) {
    s.specialOffers[uniqPolicy].seen = seen
  },
  SET_PRE_OFFER (s, { uniqPolicy, scenario, seen }) {
    s.preOffers[uniqPolicy] = {
      scenario,
      seen
    }
  },
  SET_PRE_OFFER_SEEN (s, { uniqPolicy, seen }) {
    s.preOffers[uniqPolicy].seen = seen
  },
  REMOVE_PRE_OFFER (s, uniqPolicy) {
    delete s.preOffers[uniqPolicy]
  },
  REMOVE_SPECIAL_OFFER (s, uniqPolicy) {
    delete s.specialOffers[uniqPolicy]
  },
  SET_SPECIAL_OFFER_BY_BROKER (s, { uniqPolicy, lookupCode, policyNumber, companyCode, scenario, payload, insertedDate }) {
    s.specialOffersByBroker[uniqPolicy] = {
      uniqPolicy,
      lookupCode,
      policyNumber,
      companyCode,
      scenario,
      payload,
      insertedDate
    }
  },
  SET_PRE_OFFER_BY_BROKER (s, { uniqPolicy, lookupCode, policyNumber, companyCode, payload }) {
    s.preOffersByBroker[uniqPolicy] = {
      uniqPolicy,
      lookupCode,
      policyNumber,
      companyCode,
      payload
    }
  },
  REMOVE_SPECIAL_OFFER_BY_BROKER (s, uniqPolicy) {
    delete s.specialOffersByBroker[uniqPolicy]
  },
  REMOVE_PRE_OFFER_BY_BROKER (s, uniqPolicy) {
    delete s.preOffersByBroker[uniqPolicy]
  },
  CLEAR_SPECIAL_OFFERS (s) {
    Object.assign(s, {})
  },
  SET_CROSS_SELL (s, { policyType, canCrossSell, hasCallback, notes }) {
    s.crossSell[policyType] = Object.assign(s.crossSell[policyType] || {}, {
      canCrossSell,
      hasCallback,
      notes
    })
  },
  SET_CROSS_SELL_QUOTE (s, { policyType, id, formData, quotes }) {
    s.crossSell[policyType].formData = {
      [id]: formData
    }
    s.crossSell[policyType].quotes = {
      [id]: quotes
    }
  },
  SET_CROSS_SELL_CALLBACK (s, policyType) {
    s.crossSell[policyType].hasCallback = true
  }
}

export const actions = {
  async setPolicies ({ commit, state: currentState }, forceRequest = false) {
    // Populate policies if every policyType is empty or forceRequest
    if (forceRequest || Object.values(currentState).every(o => Object.keys(o).length === 0)) {
      const policyTypeMap = {
        auto: {
          key: 'auto',
          policies: {},
          crossSellRule: () => { return false } // no rule currently
        },
        home: {
          key: 'home',
          policies: {},
          crossSellRule: (policyTypeMap) => {
            return Object.keys(policyTypeMap.auto.policies).length && !Object.keys(policyTypeMap.home.policies).length
          }
        },
        commercial_auto: {
          key: 'commercialAuto',
          policies: {},
          crossSellRule: () => { return false } // no rule currently
        },
        commercial_property: {
          key: 'commercialProperty',
          policies: {},
          crossSellRule: () => { return false } // no rule currently
        }
      }
      const res = await Vue.prototype.$api.getPolicies()
      if (res && res.length) {
        res.forEach((policy) => {
          if (Object.keys(policyTypeMap).includes(policy.policy_type_code)) {
            policyTypeMap[policy.policy_type_code].policies[policy.uniq_policy] = Vue.prototype.$format.keysToCamel(policy)
          }
        })
        const canCrossSell = Object.keys(policyTypeMap).filter(policyType => policyTypeMap[policyType].crossSellRule(policyTypeMap))
        let crosActivities = []
        let crosNotes = []
        if (canCrossSell.length) {
          // NOTE: this logic will need to be adjusted once we have other policyType's allowed for crossSell
          const thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString()
          crosActivities = (await Vue.prototype.$api.getActivitiesByCode('CROS')).filter(x => x.insertedDate.localeCompare(thirtyDaysAgo) > -1)
          if (crosActivities.length) {
            crosNotes = await Promise.all(canCrossSell.map(() => {
              const [latestCros] = crosActivities.sort((a, b) => b.insertedDate.localeCompare(a.insertedDate))
              return Vue.prototype.$api.getActivityNotes(latestCros.activityId)
            }))
          }
        }
        Object.values(policyTypeMap).forEach((policy) => {
          commit('SET_POLICIES', {
            policyType: policy.key,
            policies: policy.policies
          })
          commit('SET_CROSS_SELL', {
            policyType: policy.key,
            canCrossSell: canCrossSell.includes(policy.key),
            hasCallback: crosActivities.some(x => x.closedStatus === ''), // callbacks are the only open CROS activities
            notes: canCrossSell.includes(policy.key) && crosNotes.length ? crosNotes.shift() : []
          })
        })
      }
    }
  },
  setSpecialOffer ({ commit, state: currentState }, { uniqPolicy, policyNumber, activityCode, scenario, insertedDate }) {
    let seen = false
    if (Object.keys(currentState.specialOffers).includes(uniqPolicy.toString())) {
      seen = currentState.specialOffers[uniqPolicy.toString()].seen
    }
    commit('SET_SPECIAL_OFFER', { uniqPolicy, policyNumber, activityCode, scenario, insertedDate, seen })
  },
  setSpecialOfferSeen ({ commit }, { uniqPolicy, seen }) {
    commit('SET_SPECIAL_OFFER_SEEN', { uniqPolicy, seen })
  },
  clearSpecialOffers ({ commit }) {
    commit('CLEAR_SPECIAL_OFFERS')
  },
  setPreOffer ({ commit, state: currentState }, { uniqPolicy, scenario }) {
    let seen = false
    if (Object.keys(currentState.preOffers).includes(uniqPolicy.toString())) {
      seen = currentState.preOffers[uniqPolicy.toString()].seen
    }
    commit('SET_PRE_OFFER', { uniqPolicy, scenario, seen })
  },
  setPreOfferSeen ({ commit }, { uniqPolicy, seen }) {
    commit('SET_PRE_OFFER_SEEN', { uniqPolicy, seen })
  },
  removePreOffer ({ commit }, { uniqPolicy }) {
    commit('REMOVE_PRE_OFFER', uniqPolicy)
  },
  removeSpecialOffer ({ commit }, { uniqPolicy }) {
    commit('REMOVE_SPECIAL_OFFER', uniqPolicy)
  },
  setSpecialOfferByBroker ({ commit }, { uniqPolicy, lookupCode, policyNumber, companyCode, activityCode, scenario, payload, insertedDate }) {
    commit('SET_SPECIAL_OFFER_BY_BROKER', { uniqPolicy, lookupCode, policyNumber, companyCode, activityCode, scenario, payload, insertedDate })
  },
  setPreOfferByBroker ({ commit }, { uniqPolicy, lookupCode, policyNumber, companyCode, payload }) {
    commit('SET_PRE_OFFER_BY_BROKER', { uniqPolicy, lookupCode, policyNumber, companyCode, payload })
  },
  removeSpecialOfferByBroker ({ commit }, { uniqPolicy }) {
    commit('REMOVE_SPECIAL_OFFER_BY_BROKER', uniqPolicy)
  },
  removePreOfferByBroker ({ commit }, { uniqPolicy }) {
    commit('REMOVE_PRE_OFFER_BY_BROKER', uniqPolicy)
  },
  async setPolicyApplicant ({ commit, state: currentState }, { policyId, lookupCode }) {
    const policyType = Object.keys(currentState).find(e => Object.keys(currentState[e]).includes(policyId.toString()))
    if (['auto', 'home'].includes(policyType)) {
      const applicant = await this.$api.getApplicant(lookupCode, policyType, policyId)
      if (Object.keys(applicant).length > 0) {
        commit('SET_POLICY_APPLICANT', { policyType, policyId, applicant })
      }
    }
  },
  async setPolicyPaymentDetails ({ commit, state: currentState }, { policyId, policyNumber, postalCode, forceRequest = false }) {
    const policyType = Object.keys(currentState).find(e => Object.keys(currentState[e]).includes(policyId.toString()))
    const policy = currentState[policyType][policyId]
    try {
      if (policy.companyLookupCode === 'CAAIN1' && (forceRequest || !policy.paymentDetails)) {
        if (!policy.applicant) {
          throw new Error('Applicant not found.')
        }
        const fullName = policy.applicant.fullName
        const birthDate = policyType === 'auto' ? policy.drivers.find((driver) => { return driver.nameOfDriver.toLowerCase() === fullName.toLowerCase() })?.birthDate : policy.applicant.birthDate
        console.log(`${policyNumber} - ${policyType} - ${birthDate} - ${JSON.stringify(policy.applicant)}`)
        if (!birthDate) {
          throw new Error('Birthday not found.')
        }
        const paymentDetails = await Vue.prototype.$api.getPaymentDetails({
          policyNumber,
          fullName,
          postalCode,
          dateOfBirth: birthDate.slice(0, 10).split('-').join('') // yyyymmdd
        })
        commit('SET_POLICY', { policyType, policyId, policy })
        commit('SET_POLICY_PAYMENT_DETAILS', { policyType, policyId, paymentDetails })
        commit('SET_POLICY_ERRORS', { policyType, policyId, errors: {} })
      } else if (['WAWAN1', 'PEMBR1'].includes(policy.companyLookupCode) && (forceRequest || !policy.paymentDetails)) {
        const paymentDetails = await Vue.prototype.$api.getBillingDetails({ policyNumber: policy.policyNumber, company: policy.companyLookupCode, signal: controller.signal })
        commit('SET_POLICY', { policyType, policyId, policy })
        commit('SET_POLICY_PAYMENT_DETAILS', { policyType, policyId, paymentDetails })
        commit('SET_POLICY_ERRORS', { policyType, policyId, errors: {} })
      }
    } catch (err) {
      const errors = {
        billing: err.message || err.errors[0]?.message || 'Unknown Error.'
      }
      commit('SET_POLICY_ERRORS', { policyType, policyId, errors })
    }
  },
  clearPolicies ({ commit }) {
    controller.abort()
    commit('CLEAR_POLICIES')
  },
  async setActivities ({ commit, state: currentState }, { uniqPolicy, lookupCode, forceRequest = false }) {
    if (forceRequest || !currentState.activities[uniqPolicy] || currentState.activities[uniqPolicy].length === 0) {
      const activities = await Vue.prototype.$api.getActivities({ uniqPolicy, lookupCode })
      commit('SET_POLICY_ACTIVITIES', { uniqPolicy, activities })
      return activities
    }
    console.log(currentState.activities[uniqPolicy])
    return currentState.activities[uniqPolicy]
  },
  async setChangeRequest ({ commit }, { uniqPolicy, payload, save = false, isDeleted = false }) {
    if (save) {
      const api = (payload.id) ? 'updateChangeRequest' : 'createChangeRequest'
      const body = { uniqPolicy, payload: JSON.stringify(payload), id: payload.id }

      if (api === 'updateChangeRequest') {
        body.isDeleted = isDeleted
      }
      const res = await Vue.prototype.$api[api](body)
      const resObj = res.data[api]
      const cr = JSON.parse(resObj.payload)
      commit(
        'SET_CHANGE_REQUEST',
        {
          uniqPolicy,
          payload: {
            ...cr,
            id: resObj.id,
            createdAt: resObj.createdAt,
            updatedAt: resObj.updatedAt
          }
        }
      )
    } else {
      commit('SET_CHANGE_REQUEST', { uniqPolicy, payload })
    }
  },
  setChangeRequestPolicyType ({ commit }, policyType) {
    commit('SET_CHANGE_REQUESTS_KEY', { key: 'policyType', value: policyType })
  },
  setChangeRequestPolicy ({ commit }, uniqPolicy) {
    commit('SET_CHANGE_REQUESTS_KEY', { key: 'uniqPolicy', value: uniqPolicy })
  },
  async discardChangeRequest ({ commit, state: currentState }, uniqPolicy) {
    const changeRequest = currentState.changeRequests[uniqPolicy]
    if (changeRequest.id) {
      /* eslint-disable no-unused-vars */
      if (changeRequest.vehicleDetails?.billSaleKey) {
        await Vue.prototype.$api.storageRemove({ key: changeRequest.vehicleDetails.billSaleKey })
      }
      if (changeRequest.vehicleDetails?.ownershipDocsKey) {
        await Vue.prototype.$api.storageRemove({ key: changeRequest.vehicleDetails.ownershipDocsKey })
      }
      await Vue.prototype.$api.updateChangeRequest({ id: changeRequest.id, uniqPolicy, payload: JSON.stringify(changeRequest), isDeleted: true })
      /* eslint-enable no-unused-vars */
    }
    commit('REMOVE_CHANGE_REQUEST', uniqPolicy)
    commit('REMOVE_CHANGE_REQUESTS_KEY', 'policyType')
    commit('REMOVE_CHANGE_REQUESTS_KEY', 'uniqPolicy')
  },
  async discardExpiredChangeRequest ({ commit }, changeRequest) {
    const uniqPolicy = changeRequest.policy.uniqPolicy
    /* eslint-disable no-unused-vars */
    if (changeRequest.vehicleDetails?.billSaleKey) {
      await Vue.prototype.$api.storageRemove({ key: changeRequest.vehicleDetails.billSaleKey })
    }
    if (changeRequest.vehicleDetails?.ownershipDocsKey) {
      await Vue.prototype.$api.storageRemove({ key: changeRequest.vehicleDetails.ownershipDocsKey })
    }
    await Vue.prototype.$api.updateChangeRequest({ id: changeRequest.id, uniqPolicy, payload: JSON.stringify(changeRequest), isDeleted: true })
    /* eslint-enable no-unused-vars */
    commit('REMOVE_CHANGE_REQUEST', uniqPolicy)
    commit('REMOVE_CHANGE_REQUESTS_KEY', 'policyType')
    commit('REMOVE_CHANGE_REQUESTS_KEY', 'uniqPolicy')
  },
  setCrossSellQuote ({ commit }, { policyType, id, formData, quotes }) {
    commit('SET_CROSS_SELL_QUOTE', { policyType, id, formData, quotes })
  },
  setCrossSellCallback ({ commit }, policyType) {
    commit('SET_CROSS_SELL_CALLBACK', policyType)
  }
}

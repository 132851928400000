//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiClose, mdiChevronLeft } from '@mdi/js'
export default {
  props: {
    vuetifyProps: {
      type: Object,
      default () {
        return {}
      }
    },
    backButton: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      icons: {
        mdiClose,
        mdiChevronLeft
      }
    }
  },
  computed: {
    validVuetifyProps () {
      // merge defaults with vuetifyProps
      const defaults = {
        overlayColor: 'scoopBlack',
        overlayOpacity: 0.5,
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        tile: this.$vuetify.breakpoint.smAndDown,
        light: true,
        persistent: true,
        width: 423
      }
      return { ...defaults, ...this.vuetifyProps }
    }
  }
}

import Vue from 'vue'

class GoolgetagService {
  pushDatalayer ({ event, ...variables }) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event,
      ...variables
    })
  }
}

export default (context, inject) => {
  const googletagService = new GoolgetagService(context.store)
  context.$googletag = Vue.prototype.$googletag = Vue.$googletag = googletagService
  inject('googletag', googletagService)
}

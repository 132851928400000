const validatePermissions = (pagePermissions, group, scope) => {
  scope = scope.replace(/admin-/g, '')

  if (group === '') {
    console.error('Unrecognized group')
    return false
  }

  if (group.match(/^(?:Admin|Service|Sales)$/) && scope === 'profile') {
    return false
  }

  return !!pagePermissions[group].find(g => g === scope)
}

export default async ({ redirect, route, store, app, error }) => {
  // this usually mean 404, return so it can be handled by layouts/error.vue
  if (!route.name) {
    return
  }

  const requestedRoute = { ...route }
  let redirectRoute = null

  await store.dispatch('auth/load', { bypassCache: true })
  const reNonAuthRoutes = /^(login|register|forgot-password|confirm-reset-password|sso)___(en|fr)$/
  if (!store.state.auth.isAuthenticated) {
    if (!route.name.match(reNonAuthRoutes)) {
      redirectRoute = { name: `login___${app.i18n.locale}` }
    }
  } else if (route.name.match(reNonAuthRoutes)) {
    // clear lookup code for already logged in Admins/Brokers going to SSO
    if (route.name === `sso___${app.i18n.locale}`) {
      await app.$api.setLookupCodes('', { domains: [] })
    }
    redirectRoute = { name: `index___${app.i18n.locale}` }
  }

  if (store.state.auth.isAuthenticated) {
    // clear lookup code for Admins/Brokers after login through SSO
    if (route.name === `index___${app.i18n.locale}` && route.query.code && route.query.state && app.$auth.isViewAs) {
      await app.$api.setLookupCodes('', { domains: [] })
      redirectRoute = { name: `admin-users___${app.i18n.locale}` }
    }

    // logic to redirect Admins and Brokers to users if they do not have a lookup code
    if (route.name.match(/^(index|payments|claims|documents)___(en|fr)$/) && !app.$auth.isCustomer && !app.$auth.isViewAs) {
      redirectRoute = { name: `admin-users___${app.i18n.locale}` }
    }

    // validate the page permission for the authenticated users (unless we're redirecting)
    if (!redirectRoute) {
      // check and redirect (if necessary) user group to appropriate domain
      if (app.$auth.isWorker && /my.scoopinsurance/.test(location.href)) {
        location.href = location.origin.replace(/my.scoopinsurance/, 'broker.scoopinsurance') + requestedRoute.fullPath
      } else if (!app.$auth.isWorker && /broker.scoopinsurance/.test(location.href)) {
        location.href = location.origin.replace(/broker.scoopinsurance/, 'my.scoopinsurance') + requestedRoute.fullPath
      }
      if (route.path.includes('cross-sell') && route.params.quote_id) {
        const changeLookupCode = async (lookupCode) => {
          await app.$api.setLookupCodes(lookupCode, { domains: [] })
          await Promise.all([
            (async () => { await store.dispatch('policies/setPolicies', true) })(),
            (async () => { await store.dispatch('profile/setProfile', true) })()
          ])
          await store.dispatch('auth/load', { bypassCache: true })
        }
        const quoterDispatch = { // add new cross sell quoters to here as needed
          property: async () => {
            if (route.params.quote_id === 'new' || store.state?.policies?.crossSell?.home?.quotes?.[route.params.quote_id]) { return } // don't proceed if it's a new quote OR we already have the quote_id in the store
            const quote = await app.$api.getPropertyQuote({ id: route.params.quote_id, origin: 'CrossSell' })
            quote && app.$auth.lookupCodes !== quote.lookupCode && await changeLookupCode(quote.lookupCode) // change the user's lookupCode if it's different
          }
        }
        quoterDispatch[route.params.quoter] && await quoterDispatch[route.params.quoter]()
      }
      const scope = route.name.replace(/___en/g, '')
      const hasPermission = validatePermissions(app.$auth.pagePermissions, app.$auth.primaryGroup, scope)
      if (hasPermission === false) {
        // Raise an error so it can be handled by layouts/error.vue
        error({ statusCode: 403, message: 'Not authorized' })
      }
    }
  }

  if (redirectRoute) {
    if (redirectRoute.name === `login___${app.i18n.locale}`) {
      // we want to store the request route if the user got redirected to the login page
      // upon successful login we should check if store.state.auth.requestedRoute is set, if so, redirect to this page
      store.dispatch('auth/setRequestedRoute', requestedRoute)
    }
    redirect(redirectRoute)
  } else if (requestedRoute.name.match(reNonAuthRoutes) && requestedRoute.name !== `login___${app.i18n.locale}` && store.state.auth.requestedRoute) {
    // this is to handle instances when user navigates to non-auth route, this should out the requestedRoute
    store.dispatch('auth/setRequestedRoute', null)
  }
}

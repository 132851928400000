import Vue from 'vue'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const customParseFormat = require('dayjs/plugin/customParseFormat')
const advancedFormat = require('dayjs/plugin/advancedFormat')

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(advancedFormat)

export default (context, inject) => {
  context.$dayjs = Vue.prototype.$dayjs = Vue.$dayjs = dayjs
  inject('dayjs', dayjs)
}
